<template>
  <div v-if="imageInfo" class="modal-mask">
    <div class="modal-content-container">
      <div class="fullscreen-image__header-container">
        <span class="fullscreen-image__header-title">{{ imageTitle }}</span>
        <span @click="closeDialog" class="material-icons fullscreen-image__closebtn-icon">
          cancel
        </span>
      </div>
      <div :style="{ backgroundImage: `url(${imageInfo})` }" class="fullscreen-image__bg-image-container"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenImage',
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    imageInfo: {
      type: String,
    },
    imageTitle: {
      type: String,
      required: false,
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container{
  /* background-color: white; */
  min-width: 50vw;
  border-radius: 12px;
}
.fullscreen-image__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  /* background-color: var(--primary-color); */
}
.fullscreen-image__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.fullscreen-image__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.fullscreen-image__bg-image-container {
  width: 60vw;
  height: 60vh;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin: 40px;
  background-position: center;
}
</style>