<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Configurações de Campanha
      </div>
    </div>
    <div class="page-title">
      Configurações de Campanha
    </div>
    <div v-if="confs" class="campaign-config__container">
      <CampaignStateSetting v-for="(st, i) in states"
                            :key="st.fu"
                            :fu="st.fu"
                            :left="i%2 == 0"
                            :confs="confs"
                            @toggle="toggle_state"
                            @reload="load"
                            :is_active="campaign_active_state.some(s => s.fu == st.fu)" />
    </div>
  </div>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col modal-header d-block">
                        <h5>
                            <i class="header-icon" :class="'fas fa-cogs'"></i>
                            Configurações do Sistema
                        </h5>
                        <p class="subtitle">Gerencie configurações gerais do sistema</p>
                    </div>
                    <div class="col col-12 shadow rounded" v-if="confs">
                        <div class="row mt-2">
                            <div class="col">
                                <p class="conf-header">
                                    <i class="header-icon" :class="'fa fa-shopping-bag'"></i>
                                    Configuração de Campanhas
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-6">
                                <div class="row d-flex justify-content-end">
                                    <div class="col col-2 actions">
                                        <label for="" class="label-control">Estado</label>
                                    </div>
                                    <div class="col col-1 actions">
                                        <label for="" class="label-control">Ativo</label>
                                    </div>
                                    <div class="col col-5 actions">
                                        <label for="" class="label-control">Banner</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-6">
                                <div class="row d-flex justify-content-start">
                                    <div class="col col-2 actions">
                                        <label for="" class="label-control">Estado</label>
                                    </div>
                                    <div class="col col-1 actions">
                                        <label for="" class="label-control">Ativo</label>
                                    </div>
                                    <div class="col col-5 actions">
                                        <label for="" class="label-control">Banner</label>
                                    </div>
                                </div>
                            </div>
                            <CampaignStateSetting
                                v-for="(st, i) in states"
                                :key="st.fu"
                                :fu="st.fu"
                                :left="i%2 == 0"
                                :confs="confs"
                                @toggle="toggle_state"
                                @reload="load"
                                :is_active="campaign_active_state.some(s => s.fu == st.fu)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import SysConfService from "@/services/v3/management/sysconf.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import UserService from "@/services/v1/user.service"
import CampaignStateSetting from './_campaign_state_setting.vue'

export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationObserver,
        ValidationProvider,
        CampaignStateSetting
    },
    data(){
        return{
          image:"/images/search.png",
            loading: false,
            confs: null,
            campaign_active_state: [],
            states: [],
            message: null,
            user_svc: new UserService(),
            svc: new SysConfService(),
            breadcrumbs: [
                {
                    name: "Configurações do Sistema",
                    route: 'settings'
                }
            ]
        }
    },
    methods: {
        toggle_state(fu) {
            let remove = this.campaign_active_state.some(s => s.fu == fu)
            let states = this.campaign_active_state
            states = remove ? this.campaign_active_state.filter(s => s.fu != fu) : [ ...states, { fu } ]
            let confirm_callback = () => this.campaign_active_state = states
            this.activate_state(states, confirm_callback)
        },
        load_region_data() {
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.states = data.estados.map(c => ({ id: c.est_id, fu: c.est_sigla }))
			})
		},
        activate_state(states, confirm_callback) {
            let state_strs = states.map(a => a.fu).join(",")
            this.confirm_action({
                message: `Confirma atualização de estado para campanhas?`,
                subtitle: `Todos os clientes de ${state_strs} serão habilitados e redirecionados para campanhas após login`,
                callback: () => {
                    this.present_loader(`atualizando estados ${state_strs}`)
                    this.svc.activate_state_campaign(states.map(a => a.fu)).then(() => {
                        this.loading = false
                        this.load().then(() => {
                            this.present_success('Configuração atualizada com sucesso!')
                            if(confirm_callback) {
                                confirm_callback()
                            }
                        })
                    }).catch(err => {
                        ErrorHandlerService.handle(err, this.$store);
                    })
                }
            })
        },
        deactivate_states() {
            this.confirm_action({
                message: `Confirma desativação de redirecionamento para campanhas?`,
                subtitle: `Todos os clientes serão direcionados para pagina inicial de cotações`,
                callback: () => {
                    this.loading = true
                    this.svc.deactivate_state_campaign().then(() => {
                        this.loading = false
                        this.load().then(() => {
                            this.present_success('Configuração desativada com sucesso!')
                        })
                    }).catch(err => {
                        ErrorHandlerService.handle(err, this.$store);
                    })
                }
            })
        },
        process_campaign_active_state_config() {
            let existing_conf = this.confs.find(c => c.key == 'CURRENT_CAMPAIGN_ACTIVE_STATE')
            if(existing_conf) {
                this.campaign_active_state = this.states.filter(s => existing_conf.value.includes(s.fu))
            }
        },
        load() {
            this.confs = null
            return this.svc.load().then(response => response.data).then((data) => {
                this.confs = data
                this.process_campaign_active_state_config()
                this.dismiss_loader()
            }, err => {
                this.dismiss_loader()
                ErrorHandlerService.handle(err, this.$store, this.$store);
            })
        }
    },
    async created() {
        this.present_loader("Carregando configurações")
        await this.load_region_data()
        this.load()
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .error {
        color: $lower-red;
    }

    .btnFeedbackModal {
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px $blue1;
        background-color: $secondary-color;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding: 15px
    }

    .modal-header h3,.modal-header h4, .modal-header h5 {
        margin-top: 0;
        font-weight: bolder;
        font-size: 1.15rem;
        color: $secondary-color;
        text-transform: uppercase;
    }

    .modal-header {
        border: none;
        .subtitle {
            box-shadow: inset 7px 0px 0px 0px rgba(66,103,178,1);
            padding-left: 1rem;
            color: #314b5f;
            font-size: 0.9rem;
            font-family: Helvetica,sans-serif !important;
            font-weight: lighter;
        }
    }
    .actions {
        display: flex;
        align-items: flex-end;
        button {
            margin-right: 10px;
        }
    }
    .conf-header {
        color: $secondary-color;
        text-transform: uppercase;
        font-size: 15px;
        padding: 20px;
        text-align: center;
    }
.monitors {
  font-size: 1vw;
  margin-right: 4em;
}

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.campaign-config__container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 2em 0em;
}

@media only screen and (min-width: 1440px) {
  .campaign-config__container {
    grid-template-columns: auto auto auto auto auto auto;
  }
}
@media only screen and (max-width: 1000px) {
    .page-container{font-size: 1.5vw;}
    .campaign-config__container{grid-template-columns: 1fr 1fr 1fr;}
}
@media only screen and (max-width: 820px) {
    .page-container{font-size: 1.75vw;}
    .campaign-config__container{grid-template-columns: 1fr 1fr;}
}
@media only screen and (max-width: 500px) {
    .page-container{font-size: 2.5vw;}
    .campaign-config__container{grid-template-columns: 1fr;}
}

</style>
